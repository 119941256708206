import {React} from 'react'
import dev2 from '../site/dev2.png'


const Slider = () => {
  return (
    <div data-aos="fade-right"
    data-aos-duration="1200"
    data-aos-delay="600" data-aos-once="true" className='SliderDiv'>
 <div className= "SliderText" >
  <div data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="600" data-aos-once="true" ><p >A Futuristic Mindset Company with a Clear Vision</p>
          </div>
         
  
 </div>
 <div data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="1000" data-aos-once="true" className='SliderImageMain' >
          
           <img src={dev2} alt="star" className='ImagesSlider'/>
          </div>
 </div> 
  )
}

export default Slider