import { Routes, Route } from "react-router-dom";
import { React, useEffect } from "react";
import "./App.css";
import Services from "../src/components/Services";
import Home from "../src/components/Home";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import ContactUs from "./components/ContactUs";
import Bpo from "./components/Bpo";
import Aos from "aos";
import "aos/dist/aos.css";
import OurWork from "./components/OurWork";
import TestManu from "./components/TestManu";
import { NavMobile } from "./components/NavMobile";
import SoftwareDev from "./components/SoftwareDev";
function App() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/bpo" element={<Bpo />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/ourwork" element={<OurWork />}></Route>
        <Route path="/testmanu" element={<TestManu />}></Route>
        <Route path="/navmobile" element={<NavMobile />}></Route>
        <Route path="/softdev" element={<SoftwareDev />}></Route>
      </Routes>
    </>
  );
}

export default App;
