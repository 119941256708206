import { React, useEffect } from "react";
import Header from "../components/Header";
import IntroContent from "../components/IntroContent";
import IntroSmall from "../components/IntroSmall";
import IntroSmall2 from "../components/IntroSmall2";
import IntroSmall3 from "../components/IntroSmall3";
import IntroSmall4 from "../components/IntroSmall4";
import Devimg from "../site/developer.png";
import DevRemote from "../site/digitalnm.png";
import devsmall from "../site/dev.jpeg";
import devsmall2 from "../site/dev2.jpeg";
import gispic from "../site/gis.jpeg";
import DeveloperMsg from "../components/DeveloperMsg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import TagLine from "../components/TagLine";
import Aos from "aos";
import "aos/dist/aos.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import Gis from "./Gis";
import Database from "./Database";
import ECommerce from "./ECommerce";
import MainLayout from "./MainLayout";
import StorageIcon from '@mui/icons-material/Storage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Slider from "./Slider";
import MainBody from "./MainBody";
import NewBottomSection from './NewBottomSection';
import { NavMobile } from "./NavMobile";






const Home = () => {
  return (
    <MainLayout>
    
   <Header/>
  

   <div style={{width:"100%",justifyContent:"center"}}>
    <Slider/>
   <MainBody/>
   </div>

   <div>
    <NewBottomSection/>
   </div>

  
 
   
   
    
    </MainLayout>
    
  )
}

export default Home