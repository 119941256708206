import React from 'react'
import '../index.css'

function MainLayout({children}) {

  return (
    <div>
    <header>
    </header>
    <div className='mainclass'>
    <main >
      
<div className='cont1'>
    {children}
  </div>
    </main>
    </div>
    </div>
  )
}

export default MainLayout