import React from 'react'

const NewBottomSection = () => {
  return (
    <>

  <div data-aos="fade-up"
          data-aos-duration="400"
          data-aos-delay="400" data-aos-once="true" className='bottomSection'>
<div className='consultationText'>
    Book Consultation with one of our team members.
</div>

<div className='consultSmallText'>
Take the first step towards a brighter future and supercharge your business with cutting-edge technologies, expert guidance, and unparalleled support.
</div>


  </div>

  <div data-aos="fade-up"
          data-aos-duration="400"
          data-aos-delay="500" data-aos-once="true" className='bottomSection1'>
    <button className='BottomButton'>Contact Us</button>
  </div >

  




    </>
  )
}

export default NewBottomSection