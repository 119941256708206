import React from 'react'
import Header from "../components/Header";
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
const SoftwareDev = () => {
  return (
    
<div>
<Header/>
  
  <p className='SoftwareDevBodyTxt'>Our Services</p>

<div className="ag-format-container">
  <div className="ag-courses_box">
    <div className="ag-courses_item">
    
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>
       
        <div className="ag-courses-item_title">
        
          Big Data Engineering
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Remote DBA Services
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Product+UX/UI+Graph designer&#160;
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Business Process Outsource
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          E-commerce Setup
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Front End React Js Development
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg">
        </div>
        <div className="ag-courses-item_title">
          Digital Marketing
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Search Engine Optimization
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

    <div className="ag-courses_item">
      <a href="#" className="ag-courses-item_link">
        <div className="ag-courses-item_bg"></div>

        <div className="ag-courses-item_title">
          Native Mobile Apps
        </div>

        <div className="ag-courses-item_date-box">
          <span className="ag-courses-item_date">
          </span>
        </div>
      </a>
    </div>

  </div>
</div>





</div>  
)
}

export default SoftwareDev