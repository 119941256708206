import { useCallback } from "react";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import SocialDistanceOutlinedIcon from '@mui/icons-material/SocialDistanceOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useNavigate } from "react-router-dom";

const MainBody = () => {

  const particlesInit = useCallback(async engine => {      
    await loadFull(engine);
  }, []);

const particlesLoaded = useCallback(async container => {
  await console.log(container);
}, []);

const navigate = useNavigate();

const gotoSoftwareDev =()=> {
  navigate("/softdev")
  
}

  return (
    <>


    <div>
    <div className='MainBody'>
        
<div data-aos="fade-up"
          data-aos-duration="400"
          data-aos-delay="400" data-aos-once="true" className='MainCards'>


<div className='MainCard' onClick={gotoSoftwareDev}>
<DeveloperBoardOutlinedIcon className='HeadingIcon'/>   
   <span className='CardHeading' >
Software Development
   </span>
   <br />
   <span className='CardContent' >
   <p>We can develop custom software for your needs whether it's e-commerce, portfolio, or any other management system.</p>
   </span>
</div>
<div>
</div>
<div className='MainCard'>
<PeopleAltOutlinedIcon className='HeadingIcon'/>
<span className='CardHeading'>
Resource Outsourcing
</span>
 <br />
 <span className='CardContent'>
<p>For any type of business we can provide you resources in order to meet your business requirements.</p>
 </span>
</div>
<div className='MainCard'>
<DeveloperModeIcon className='HeadingIcon'/>
<span className='CardHeading'>
Mobile App Development
</span>
<br />
<span className='CardContent'>
<p>Got an idea? We can help you to turn your dream into an app. Native development for both ios and Android.</p>
 </span>
</div>
<div className='MainCard'>
  <AddLocationOutlinedIcon className='HeadingIcon'/>
<span className='CardHeading'>
GIS Projects
</span>
<br />
<span className='CardContent'>
<p>Wast experience in designing GIS solutions ranging from Raster Processing, Digitizing, Spatial Analysis, Spatial Data Management and applications.</p>
</span>
</div>
<div className='MainCard'>
<SocialDistanceOutlinedIcon className='HeadingIcon'/>
<span className='CardHeading'>
Social Media Marketing
</span>
<br />
<span className='CardContent'>
<p>Boosting Online Presence, Engaging Audiences, Driving Traffic, Enhancing Brand Awareness, and Fostering Customer Interaction and Growth</p>
</span>
</div>
<div className='MainCard'>
<StorageOutlinedIcon className='HeadingIcon'/>
<span className='CardHeading'>
Database Management
</span>
<br />
<span className='CardContent'>
<p>Ensuring Data Security, Optimizing Performance, Streamlining Operations, Supporting Scalability, and Facilitating Reliable Data Analysis</p>
</span>
</div>
</div>


</div>


</div>



</>
  )
}

export default MainBody