import React from 'react'
import Navbar from './Navbar'
import Logofile from '../site/LogoFile.png';
import MenuIcon from '@mui/icons-material/Menu';
import TestManu from './TestManu';
import { NavMobile } from './NavMobile';
import { useNavigate } from 'react-router-dom';



const Header = () => {
const navigate= useNavigate()

  const goToHome = () => {
    navigate("/")
  }


const gotoSoftwareDev =()=> {
  navigate("/softdev")
  
}


  return (
    <>
<div style={{height:"10vmax",width:"100%",display:"flex",flexDirection:"row",justifyItems:"center",alignItems:"center",backgroundColor:"#001E36",background:"linear-gradient(to left,#13446f,#093457)"}}> 

      <div style={{paddingLeft:"5%", display:"flex",flexDirection:"row",justifyItems:"center",alignItems:"center",justifyContent:"space-evenly",
        
      }}>
        <img className='LogoImg' src={Logofile} alt="Logofile" onClick={goToHome} style={{cursor:"pointer"}}/>
        
      </div>
      
      <div className='TopMenu'>
      
      <span className='newNavButtons'>Vision</span>
      <span className='newNavButtons' onClick={gotoSoftwareDev}>Services</span>
      <span className='newNavButtons'>About</span>
      <span className='newNavButtons'>Portfolio</span>
      <span className='newNavButtons'>Contact Us</span>
      
      </div>
      <NavMobile/>
      

      
        
      
    </div>

    </> 
  )
}

export default Header