import {useState} from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import zIndex from '@mui/material/styles/zIndex';
import { Backdrop } from '@mui/material';
import { Height } from '@mui/icons-material';


const TestManu = () => {
    const [open,setOpen] = useState(false)


    const styles = {
        HamburgerManu:{
          
          display: open? "block" : "none",
          width:"75%",
          borderRadius: '10px', 
          position:"absolute",
          background:"none",
          boxShadow: "0 0 8px 1px #2c8282",
          color:"#2c8282",
          zIndex:10,
          paddingRight:"20%",
          marginRight:"-100px",
          
          
        
          
          
          
          
        },
        ThreeDot:{
            display: open? "none" : "block",
            color:"#2c8282",
            paddingLeft:"45%",
            height:"40px",
            width:"40px"
           
            
            
        },
        Clear:{
            display: open? "block" : "none",
            paddingLeft:"45%",
            color:"#2c8282",
            height:"40px",
            width:"40px"
        }
    }
  



  return (
<>
    <div className='SmallMenu'>
    <MenuIcon className='ManuAllStyles' style={styles.ThreeDot} onClick={()=>{setOpen(true)}}/>
    <ClearIcon style={styles.Clear} onClick={()=>{setOpen(false)}}/>
    <div style={styles.HamburgerManu} >
<ul className='listMenuItems'>
    <li className='List'>Home</li>
    <li className='List'>Services</li>
    <li className='List'>Portfoio</li>
</ul>
    </div>
    </div>
   

    </>
  )
}




export default TestManu