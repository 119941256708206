import React from 'react'
import Devimg from "../site/bpo.jpg"
import gispic from "../site/gis.jpeg";


const Bpo = () => {
  return (
    <div>
<span    data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="600" className='devquotebpo'>
At Xellent Solutions, we understand that in today's fast-paced business landscape, organizations need to focus on their core competencies while ensuring operational efficiency. That's where our Business Process Outsourcing (BPO) services come into play. With a track record of excellence and a commitment to delivering top-tier solutions, we are your trusted partner for all your outsourcing needs.

<img data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="600" className="dev-img" src={Devimg} alt="developer" />
</span>

<div data-aos="fade-up"
          data-aos-duration="5000"
          data-aos-delay="900" className='bpo-div'>

<div
          data-aos="fade-up"
          data-aos-duration="5000"
          data-aos-delay="300"
          style={{
            height: "180px",
            width: "200px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "whitesmoke",
            marginLeft: "20%",
            marginTop: "100px",
            position: "absolute",
            opacity: "80%",
            color: "gray",
          }}
        >
          <div
            style={{
              height: "100px",
              width: "100px",
              marginLeft: "45px",
              marginTop: "20px",
              borderRadius: "50px",
              position: "absolute",
              backgroundImage: `url(${gispic})`,
              backgroundSize: "contain",
            }}
          ></div>
          <div
            style={{
              marginTop: "125px",
              fontFamily: "Helvetica",
              color: "#362C29",
              paddingTop: "10px",
            }}
          >
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Maria Sultan
            </span>
            <br />
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Istanbul
            </span>
          </div>
        </div>

<div
          data-aos="fade-up"
          data-aos-duration="5000"
          data-aos-delay="300"
          style={{
            height: "180px",
            width: "200px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "whitesmoke",
            marginLeft: "40%",
            marginTop: "100px",
            position: "absolute",
            opacity: "80%",
            color: "gray",
          }}
        >
          <div
            style={{
              height: "100px",
              width: "100px",
              marginLeft: "45px",
              marginTop: "20px",
              borderRadius: "50px",
              position: "absolute",
              backgroundImage: `url(${gispic})`,
              backgroundSize: "contain",
            }}
          ></div>
          <div
            style={{
              marginTop: "125px",
              fontFamily: "Helvetica",
              color: "#362C29",
              paddingTop: "10px",
            }}
          >
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Maria Sultan
            </span>
            <br />
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Istanbul
            </span>
          </div>
        </div>


<div
          data-aos="fade-up"
          data-aos-duration="5000"
          data-aos-delay="300"
          style={{
            height: "180px",
            width: "200px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "whitesmoke",
            marginLeft: "60%",
            marginTop: "100px",
            position: "absolute",
            opacity: "80%",
            color: "gray",
          }}
        >
          <div
            style={{
              height: "100px",
              width: "100px",
              marginLeft: "45px",
              marginTop: "20px",
              borderRadius: "50px",
              position: "absolute",
              backgroundImage: `url(${gispic})`,
              backgroundSize: "contain",
            }}
          ></div>
          <div
            style={{
              marginTop: "125px",
              fontFamily: "Helvetica",
              color: "#362C29",
              paddingTop: "10px",
            }}
          >
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Maria Sultan
            </span>
            <br />
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Istanbul
            </span>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="5000"
          data-aos-delay="300"
          style={{
            height: "180px",
            width: "200px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "whitesmoke",
            marginLeft: "80%",
            marginTop: "100px",
            position: "absolute",
            opacity: "80%",
            color: "gray",
          }}
        >
          <div
            style={{
              height: "100px",
              width: "100px",
              marginLeft: "45px",
              marginTop: "20px",
              borderRadius: "50px",
              position: "absolute",
              backgroundImage: `url(${gispic})`,
              backgroundSize: "contain",
            }}
          ></div>
          <div
            style={{
              marginTop: "125px",
              fontFamily: "Helvetica",
              color: "#362C29",
              paddingTop: "10px",
            }}
          >
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Maria Sultan
            </span>
            <br />
            <span
              style={{
                marginLeft: "80px",
                color: "#362C29",
                fontFamily: "inherit",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              Istanbul
            </span>
          </div>
        </div>


        

</div>

    </div>
  )
}

export default Bpo